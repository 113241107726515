/* global rp$, window */

import './mobile_header.scss';

var THE_SCRIPT = function () {
	const $onReady = require('public/js/lib/on-ready');

	/********************************************
	 * Load Javascript for the Mobile Header Module
	 * -----------------------------------------
	 * @section mobile_header_js
	 ********************************************/
	(function MobileHeaderModule() {
		$onReady({rp$, window,
			label: 'mobile_header',
			fn: function () {
				var $mobile_headers, $mobile_header;

				$mobile_headers = rp$('.mobile-header-module');

				// Determine whether or not to use the modal backdrop
				// On Plugin stores, we can't count on backdrops working properly
				var getBackDropDisplay = function () {
					var displayBackdrop = true;
					if (window.__IS_WEBSTORE_PLUGIN === true) {
						var mobileExperienceBreakpoint = 680;
						var currentViewportWidth = rp$(window).outerWidth();
						displayBackdrop = (currentViewportWidth > mobileExperienceBreakpoint);
					}
					return displayBackdrop;
				}

				rp$.each($mobile_headers, function Mobile_Header_Iterator(idx, mobile_header) {
					$mobile_header = rp$(mobile_header);

					$mobile_header.find('.rp_page_supportbutton').on('click', function() {
						rp$('#support_modal').modal({
							backdrop: getBackDropDisplay(),
						});
					});
					$mobile_header.find('.rp_page_linksbutton').on('click', function() {
						rp$('#links_modal').modal({
							backdrop: getBackDropDisplay(),
						});
					});
				});

				$mobile_headers.on('click', '.mobile-header-search-button', function (e) {
					if (!rp$(this).hasClass('is-open')) {
						setTimeout(function () {
							rp$('#mobileSearch .search-bar-component input')[0].focus();
						}, 200);
						rp$(this).addClass('is-open');
					} else {
						rp$(this).removeClass('is-open');
					}
				});
			},
		});
	})();
};

try {
	// Because __CURRENT_SCRIPT__ is injected by plugin Client, we need this here to try and pass it up to the plugin code
	if (typeof __CURRENT_SCRIPT__ === 'undefined') {
		throw new Error('__CURRENT_SCRIPT__ is not defined');
	}
	__CURRENT_SCRIPT__ = THE_SCRIPT;
} catch (e) {
	THE_SCRIPT();
}
